.custom-radio{
  .custom-control-label::before{
    width: 20px;
    height: 20px;
    border: 2px solid #DDDFE0;
    background-color: #EEF2F7;
  }

  .custom-control-input:checked ~ .custom-control-label{
    color: #333333;
  }

  .custom-control-input:checked ~ .custom-control-label::before{
    background-color: #00B2A9;
    border: 2px solid #DDDFE0;
  }

  .custom-control-input:checked ~ .custom-control-label::after{
    background-image: none;
  }
}