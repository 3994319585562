aside.right-sidebar{
  height: 100%;
  background-color: #3E4757;
  position: fixed;
  right: 0;
  border-left: 1px solid #627089;
  max-width: 224px;
  transition: margin-right 0.5s;
  top: 66px;
  padding: 0;

  nav.navigation{
    ul{
      list-style: none;
      margin-top: 70px;
      padding: 0;

      a{
        color: white;
        display: block;
        font-size: 14px;
        line-height: 36px;
        height: 36px;
        text-align: right;
        text-decoration: none;

        &.active,
        &:hover{
          background-color: #627089;
          border-left: 6px solid #00B2A9;
        }

        img{
          padding: 10px 20px 10px 16px;
        }
      }
    }
  }
}
aside.right-sidebar.closed{
  margin-right: -181px;

  nav.navigation{
    ul{
      a{
        text-align: left;

        .title{
          display: none;
        }

        &.active,
        &:hover{
          background-color: #00B2A9;
          border: none;
        }
      }
    }
  }
}
@media screen and (max-width: 1280px){
  .col-md-8.offset-md-2{
    margin-left: 0 !important;
  }
}
@media screen and (max-width: 768px){
  aside.right-sidebar{
    max-width: 100%;
  }

  aside.right-sidebar.closed{
    margin-right: -100%;
  }
}