header.page-title{
  padding: 30px 0;
  background: #3E4757;
  border-radius: 6px 6px 0 0;

  h4{
    font-size: 22px;
    line-height: 28px;
    color: #FFFFFF;
    text-align: center;
  }
}

.add-entity{
  line-height: 30px;
  cursor: pointer;
}
.add-entity:before{
  float: left;
  content: '';
  width: 30px;
  height: 30px;
  border-radius: 3px;
  background: url('../img/plus-ico.svg') 50% no-repeat #00B2A9;
  margin-right: 15px;
}

.form-wrapper{
  padding: 30px;
  border-bottom: 1px solid #DDDFE0;
}
.form-wrapper:last-child{
  border: none;
}

section.application-form{
  border: 1px solid #DDDFE0;
  box-sizing: border-box;
  border-radius: 0 0 6px 6px;
  border-top: none;

  .top-buttons{
    div.code99{
      float: left;
      padding-top: 20px;
    }

    .upload-passport{
      background-image: url("../img/passport-ico.svg");
      background-position: 17px 6px;
      background-repeat: no-repeat;
      float: right;

      span{
        padding-left: 52px;
      }
    }
  }

  .upload-document{
    display: block;

    span{
      background-image: url("../img/upd-doc-ico.svg");
      background-repeat: no-repeat;
      display: inline-block;
      padding-left: 40px;
      background-position: 0 20px;
    }
  }

  h4.block-title{
    text-align: center;
    color: #333333;
    font-size: 17px;
    line-height: 24px;
  }

  .work-exp{
    color: #9299A2;
    text-align: center;
    font-size: 17px;
    margin-bottom: 4px;
  }

  .uploaded-documents{
    span{
      padding-left: 10px;
    }

    .success:after{
      content: '';
      background: url('../img/upd-success-ico.svg');
      width: 14px;
      height: 10px;
      display: inline-block;
      margin-left: 16px;
    }
  }

  .scoring-agr{
    line-height: 66px;
  }
}