/* Base for label styling */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 45px;
  cursor: pointer;
  margin-bottom: 0;
  display: inline-block;
  font-size: 17px;
  line-height: 30px;
}
/* checkbox aspect */
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  left: 0; top: 0;
  width: 30px; height: 30px;
  border: none;
  background: #eef2f7;
  border-radius: 3px;
}
[type="checkbox"]:checked + label:before{
  background-color: #00B2A9;
}
/* checked mark aspect */
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  content: '✔';
  position: absolute;
  top: 2px; left: 8px;
  font-size: 18px;
  line-height: 1.5;
  color: #ffffff;
  transition: all .2s;
}
/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
/* disabled checkbox */
[type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before {
  background-color: #c7c7c7;
}
[type="checkbox"]:disabled:checked + label:after {
  color: #999999;
}
[type="checkbox"]:disabled + label {
  color: #aaaaaa;
}
[type="checkbox"]:checked + label {
  color: #333333;
}