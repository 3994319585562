.floating-label {
  position: relative;

  input,
  select{
    display: block;
    width: 100%;
    background-color: transparent;
    border: none;
  }

  input:focus,
  select:focus{
    outline: none;
  }

  label{
    color: #9299A2;
    position: relative;
    pointer-events: none;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    display: block;
    top: -39px;
    left: 15px;
    font-size: 17px;
    line-height: 26px;
    margin: 0;
  }

  input:focus~label,
  input:not(:placeholder-shown)~label,
  select:focus~label,
  select:not([data-value=""]):valid~label{
    font-size: 13px;
    line-height: 18px;
    top: -57px;
  }
}