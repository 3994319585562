.table.table-custom {
  .selectable tbody tr {
    cursor: pointer;
  }

  .selectable tr.active {
    background-color: #3A8BBB;
    border-radius: 6px;
    color: white;
  }

  .selectable tr.active td {
    border-bottom: none;
  }

  .selectable tr.active td:first-child {
    border-radius: 6px 0 0 6px;
  }

  .selectable tr.active td:last-child {
    border-radius: 0 6px 6px 0;
  }

  .selectable td {
    padding: 28px;
    position: relative;
  }

  .selectable td .fa.fa-check-circle-o,
  .selectable td .fa.fa-circle-thin {
    font-size: 24px;
    position: absolute;
    top: 50%;
    left: 5px;
    margin-top: -12px;
  }

  .selectable td .fa.fa-circle-thin {
    color: #DDDFE0;
  }

  /** Custom table */
  .pad-st td {
    padding: .75rem;
  }

  th,
  td {
    border-bottom: 1px solid #DDDFE0;
    border-top: none;
  }

  th:first-child,
  td:first-child {
    padding-left: 0;
  }

  th {
    cursor: pointer;
    font-size: 14px;
    line-height: 17px;
    color: #9299A2;
    font-weight: normal;
    vertical-align: top;
    position: relative;
    padding-right: 20px;
  }

  th a {
    color: #9299A2;
  }

  th i {
    position: absolute;
    top: 13px;
    right: 0;
  }

  th .fa-sort-up,
  th .fa-sort-down {
    color: #00B2A9;
  }

  td {
    padding: 28px;
    vertical-align: middle;
    position: relative;
  }

  td a {
    color: #00B2A9;
  }

  td:last-child .fa-ellipsis-h {
    position: absolute;
    color: #DDDFE0;
    right: 25px;
    cursor: pointer;
    font-size: 24px;
  }

  th.unload {
    min-width: 165px;
  }

  th.unload div {
    margin-top: 5px;
    cursor: pointer;
  }

  th.unload i {
    font-size: 24px;
    float: left;
    margin-right: 7px;
    line-height: 31px;
    position: inherit;
    top: 0;
  }

  th.unload .fa-circle-thin {
    color: #DDDFE0;
  }

  th.unload .fa-check-circle-o {
    color: #00B2A9;
  }

  td.td-ico i {
    font-size: 24px;
    margin-right: 8px;
    float: left;
  }

  .processing th .header {
    font-size: 18px;
    line-height: 24px;
    color: #333333;
    font-weight: bold;
  }

  .processing th .date-range-picker {
    color: #9299A2;
    cursor: pointer;
    margin-top: 10px;
    display: block;
  }

  .processing th .date-range-picker i {
    position: inherit;
    font-size: 17px;
  }

  .processing th .date-range-picker input {
    border: none;
    font-size: 14px;
    height: 17px;
    color: #9299A2;
    cursor: pointer;
  }

  .processing td:first-child {
    color: #9299A2;
    padding-left: 0;
  }

  .processing td {
    font-size: 14px;
    line-height: 17px;
  }

  div.empty {
    text-align: center;
    margin: 100px 0;
    font-size: 20px;
    font-weight: bold;
    color: #9299A2;
  }

  /** Telemarketing */
  .telemarketing td a {
    color: #00B2A9;
  }

  .telemarketing th .fa-sort-up,
  .telemarketing th .fa-sort-down {
    color: #00B2A9;
  }

  .telemarketing td {
    font-size: 16px;
    line-height: 19px;
  }

  /** Table doc uploading*/
  .uploaded-files td,
  .doc-print td {
    color: #9299a2;
    font-size: 14px;
  }

  .uploaded-files td:first-child,
  .doc-print td:first-child {
    color: #333333;
  }

  .uploaded-files td i,
  .doc-print td i {
    cursor: pointer;

  }
}