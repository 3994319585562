header.navbar{
  background-color: #3E4757;
  border-bottom: 1px solid #627089;

  .col-md-2,
  .col-md-6{
    padding: 0;
  }

  .user{
    margin-bottom: 3px;
    min-width: 142px;

    .avatar{
      float: left;
      margin: 4px 12px 0 0;
    }

    .name{
      color: white;
      font-size: 14px;
      line-height: 20px;
      margin-top: 5px;
      float: left;
      margin-bottom: 0;
    }

    .settings{
      margin: 16px 0 0 20px;
      width: 18px;
      height: 18px;
      background: url("../img/settings-ico.svg") no-repeat;
      cursor: pointer;
      line-height: 66px;
      border: none;
    }
  }

  .search{
    position: relative;

    input[type="text"]{
      height: 36px;
      border-radius: 18px;
      border: none;
      width: 80%;
    }

    button[type="submit"]{
      position: absolute;
      background: url('../img/search-ico.svg') no-repeat;
      width: 13px;
      height: 13px;
      top: 5px;
      right: 20px;
      border: none;
    }
  }

  .navbar-toggler{
    border: none;
    padding-right: 0;

    .navbar-toggler-icon{
      background: url("../img/toggle-menu-ico.svg") no-repeat;
      width: 18px;
      height: 12px;
    }
  }

  .logo{
    max-width: 224px;
    transition: 0.5s;
    padding: 0;
  }

  .logo.closed{
    max-width: 43px;

    .logo-let{
      display: none !important;
    }
  }

  .logo-let{
    width: 150px !important;
  }
}
