.template-custom-controls{
  .btn.btn-success{
    background-color: #00B2A9;
    border-radius: 6px;
    line-height: 66px;
    border: none;
    cursor: pointer;
    padding: 0 20px;
    color: white;
    margin: 0;
  }
  .btn.btn-success:active{
    background-color: #00B2A9;
  }

  .custom-checkbox{
    color: #9299A2;

    input[type="checkbox"]{
      width: 30px;
      height: 30px;
    }
  }

  .custom-control{
    background: #EEF2F7;
    border-radius: 5px;
    height: 66px;
    margin-bottom: 30px;
    padding: 0;
    position: relative;

    input,
    select{
      padding: 25px 15px 15px 15px;
    }
  }

  .custom-control:focus-within{
    background: #FFFFFF;
    border: 1px solid #DDDFE0;
    box-sizing: border-box;
  }

  .phone-types{
    height: 64px;
    padding-top: 18px;
  }
  .phone-types .custom-radio{
    margin-left: 44px;
  }

  .phone-types .custom-radio label{
    color: #9299A2;
    padding: 3px 0 0 4px;
  }

  .was-validated .custom-control input:invalid,
  .was-validated .custom-control select:invalid{
    border: 1px solid #DD5656;
    color: #DD5656;
    border-radius: 5px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='26' height='26' fill='%23DD5656' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23DD5656' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: 98% center;
  }
  .was-validated .custom-control input:invalid ~ .invalid-feedback,
  .was-validated .custom-control select:invalid ~ .invalid-feedback,
  .was-validated .custom-checkbox input:invalid ~ .invalid-feedback{
    display:  block;
    position: absolute;
    top: 65px;
    left: 5px;
  }

  .was-validated .custom-control input:invalid ~ label,
  .was-validated .custom-control select:invalid ~ label,
  .was-validated .custom-checkbox input:invalid  ~ label{
    color: #DD5656;
  }

  .was-validated .custom-control input:valid,
  .was-validated .custom-control select:valid{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='0 0 8 8'%3e%3cpath fill='%2303BC00' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: 98% center;
  }

  /*.was-validated .custom-checkbox input:valid ~ label,
  .was-validated .custom-control input:valid ~ label,
  .was-validated .custom-control select:valid ~ label{
    color: #28a745;
  }*/

}