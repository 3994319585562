@import "~bootstrap";
@import "custom/fira-sans-font";
@import "custom/spacing-helpers";
@import "custom/checkboxes";
@import "custom/multi-select";
@import "custom/floating-labels";
@import "custom/radios";
@import "custom/outlines";
@import "custom/create-form";
@import "custom/custom-controls";
@import "custom/header";
@import "custom/right-sidebar";
@import "custom/_api.scss";
@import "custom/_table-custom";

body{
  font-family: 'Fira sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
}

.spinner-loader{
  margin: 150px auto 0 auto;
}

@media screen and (max-width: 780px){
  .col-md-8{
    padding: 0;
  }
}

// TODO DAF-223
#todo-daf-223{
  display: none;
}