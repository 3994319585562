.b_bg_red {
  background: #822 !important;
}

.xml_container {
  margin: 40px 0;
}

.display_disable {
  display: none;
}
