textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active
{
  outline:0 !important;
  -webkit-appearance:none;
  box-shadow: none !important;
}
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: none;
  box-shadow: inset 0 1px 1px rgba(255,255,255,0.075), 0 0 2px red;
}